<template>
<refund-card title="退费详情" :model="value" :rules="rules" v-if="!isShow" ref="refundCard">
  <el-form-item label="退费金额：" prop="money" required>
    <el-input type="number" v-model="value.money" placeholder="请输入金额"/>
  </el-form-item>
  <el-form-item label="退费时间：" prop="refund_day" required>
    <el-date-picker value-format="yyyy-MM-dd" v-model="value.refund_day" placeholder="请选择"></el-date-picker>
  </el-form-item>
  <el-form-item label="退费状态：" prop="refund_status" required>
    <el-select v-model="value.refund_status" placeholder="请选择">
      <el-option :value="1" label="已退费"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="退费方式：" prop="refund_type" required>
    <el-select v-model="value.refund_type" placeholder="请选择">
      <el-option :value="1" label="线下打款"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="家长银行账户：" prop="bank_card_number" required>
    <el-input type="number" v-model="value.bank_card_number" placeholder="请输入卡号"/>
  </el-form-item>
  <el-form-item label="开户行：" prop="opening_bank" required>
    <el-input v-model="value.opening_bank" Placeholder="请输入"/>
  </el-form-item>
  <el-form-item label="开户人：" prop="account_holder" required>
    <el-input v-model="value.account_holder"  Placeholder="请输入"/>
  </el-form-item>
  <el-form-item label="退费办理人：" prop="refund_transactors_id" required>
    <el-select v-model="value.refund_transactors_id" filterable remote reserve-keyword placeholder="请输入" @change="handleChange" :remote-method="remoteMethod" :loading="loading">
      <el-option v-for="item in refund_transactors" :key="item.account_id" :label="item.name" :value="item.account_id"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="说明：" prop="describe" required>
    <el-input type="textarea" v-model="value.describe" class="el-input" resize="none" Placeholder="请输入"/>
  </el-form-item>
  <el-form-item label="退费凭证：" prop="refund_img">
    <t-upload v-model="value.refund_img" />
  </el-form-item>
</refund-card>
<refund-card v-else title="退费详情">
  <el-form-item label="退费金额：" >
    {{value.money}}
  </el-form-item>
  <el-form-item label="退费时间：" >
    {{value.refund_day}}
  </el-form-item>
  <el-form-item label="退费状态：" >
    {{value.refund_status_name}}
  </el-form-item>
  <el-form-item label="退费方式：" >
    {{ value.refund_type_name }}
  </el-form-item>
  <el-form-item label="家长银行账户：" >
    {{value.bank_card_number}}
  </el-form-item>
  <el-form-item label="开户行：" >
    {{value.opening_bank}}
  </el-form-item>
  <el-form-item label="开户人：" >
    {{value.account_holder}}
  </el-form-item>
  <el-form-item label="退费办理人：" >
    {{value.refund_transactors_name}}
  </el-form-item>
  <el-form-item label="说明：" >
    {{value.describe}}
  </el-form-item>
  <el-form-item label="退费凭证：" v-if="!!value.refund_img">
    <el-image :src="value.refund_img" style="width:150rem;max-height: 200rem" :preview-src-list="[value.refund_img]" />
  </el-form-item>
</refund-card>
</template>

<script>
import RefundCard from "./RefundCard";
import TUpload from '@/components/common/upload.vue'
export default {
  name: "RefundEdit",
  props:{
    isShow:Boolean,
    value:{
      type:Object,
      default:()=> ({})
    },
    rules:{
      type:Object,
      default:()=>({
        money:{required:true,message:"请输入退费金额"},
        refund_day:{required:true,message:"请选择退费时间"},
        refund_status:{required:true,message:"请选择退费状态"},
        refund_type:{required:true,message:"请选择退费方式"},
        bank_card_number:{required:true,message:"请输入家长银行账户"},
        opening_bank:{required:true,message:"请输入开户行"},
        account_holder:{required:true,message:"请输入开户人"},
        refund_transactors_id:{required:true,message:"请选择退费办理人"},
        describe:{required:true,message:"请输入退费说明"},
      })
    },
  },
  data(){
    return{
      refund_transactors:[],
      loading:false,
    }
  },
  watch:{
    value(v,ov){
      this.$emit("input",v)
      if (ov.refund_transactors_id == null && v.refund_transactors_id != null){
        this.remoteMethod(v.refund_transactors_name)
      }
    }
  },
  methods:{
    validate(callback){
      this.$refs.refundCard.validate(callback)
    },
    handleChange(val){
    },
    remoteMethod(query){
      if (query !== '') {
        this.loading = true
        this.$_register.get("api/refund/offline-information/search-refund-transactors",{
          params:{
            name:query
          }
        }).then(res=>{
          this.refund_transactors = res.data.data
        }).finally(()=>this.loading = false)
      } else {
        this.refund_transactors = []
      }
    }
  },
  components:{RefundCard,TUpload}
}
</script>

<style scoped>

</style>
