<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: '请上传小于20MB的png/jpg格式的图片'
    }
  },
  data() {
    return {
      src: '',
      showSrc:null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.showSrc = val
        this.src = val
      }
    },
    src(val) {
      this.$emit('change', val)
      this.$emit('input',val)
    }
  },
  methods: {
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 20MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M
    },
    handleRequest(row) {
      const loading = this.$loading({
        lock: true,
        text: '上传中',
        customClass: 'img-upload-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$uploadToOSS_1(row.file, 3, 1)
        .then(res => {
          const windowURL = window.URL || window.webkitURL;
          this.showSrc = windowURL.createObjectURL(row.file);
          this.src = res
        })
        .finally(() => {
          loading.close()
        })
    },
    handleRemove() {
      this.src = ''
    }
  }
}
</script>

<template>
  <div class="">
    <el-upload action="xxx" :show-file-list="false" :before-upload="beforeUpload" :http-request="handleRequest" :on-remove="handleRemove">
      <div class="zhu-uploader-box mb-2" slot="trigger">
        <div v-if="src">
          <el-button type="primary" size="mini">重新选择</el-button>
        </div>
        <div v-else class="zhu-uploader-inner"><i class="el-icon-plus zhu-uploader-icon"></i></div>
      </div>
      <el-button v-if="src" class="ml-1" type="" size="mini" @click="handleRemove">删除图片</el-button>
      <div>
        <el-image v-if="src" style="width: 200rem; height: 200rem" :src="showSrc" fit="fit" :preview-src-list="[showSrc]"></el-image>
        <div slot="tip" class="zhu-uploader-tip">{{ tips }}</div>
      </div>
    </el-upload>
  </div>
</template>

<style lang="scss" scoped>
.zhu-uploader {
  &-inner {
    border: 1rem dashed #d9d9d9;
    border-radius: 6rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
      .zhu-uploader-icon {
        color: #409eff;
      }
    }
  }
  &-icon {
    font-size: 28rem;
    color: #8c939d;
    width: 178rem;
    height: 178rem;
    line-height: 178rem;
    text-align: center;
  }
  &-tip {
    font-size: 12rem;
    line-height: 1;
    color: rgba($color: #000000, $alpha: 0.6);
  }
}
</style>
