<template>
  <t-card :title="title" class="card" :title-style="titleStyle">
    <el-form ref="form" v-if="type === 'form'" :model="model" :rules="rules" class="content" label-width="150rem">
      <slot></slot>
    </el-form>
    <slot v-else></slot>
  </t-card>
</template>

<script>
export default {
  name: "TRefundCard",
  props:{
    title:String,
    model:{
      type:Object,
      default:null
    },
    rules:{
      type:Object,
      default:null
    },
    type:{
      type:String,
      default:"form"
    }

  },
  data(){
    return{
      titleStyle:{
        borderBottom:'1rem solid #eee',
        paddingBottom:'10rem',
        display:'block'
      },
    }
  },
  methods:{
    validate(callback){
      this.$refs.form.validate(callback)
    },
  }
}
</script>

<style lang="scss" scoped>
.card{
  background: white;
  padding:10rem;
  margin-bottom: 20rem;
  .content{
    padding: 20rem 30rem;
    .uploader{

    }
  }
}
</style>
