<template>
  <div class="refund">
    <t-card title="基础信息:" class="card" :title-style="titleStyle">
      <el-form class="content" label-width="130rem">
        <el-form-item label="学生姓名：">
          {{ baseInfo.student_name }}
        </el-form-item>
        <el-form-item label="学生身份证号：">
          {{ baseInfo.student_idcard }}
        </el-form-item>
        <el-form-item label="报名学校：">
          {{ baseInfo.school_name }}
        </el-form-item>
        <el-form-item label="购买商品：">
          {{ baseInfo.goods_pack_name }}
        </el-form-item>
        <el-form-item label="订单号：">
          {{ baseInfo.order_number }}
        </el-form-item>
        <el-form-item label="订单状态：">
          {{ baseInfo.order_status }}
        </el-form-item>
        <el-form-item label="支付金额：">
          {{ baseInfo.paid_money }}
        </el-form-item>
      </el-form>
    </t-card>
<!--    <t-card title="退费详情" class="card" :title-style="titleStyle">
      <el-form :model="form" :rules="rules" class="content" label-width="130rem">
        <el-form-item label="退费金额：" prop="_1" required>
          <el-input type="number" v-model="form._1" placeholder="请输入金额"/>
        </el-form-item>
        <el-form-item label="退费时间：" prop="_2" required>
          <el-date-picker v-model="form._2" placeholder="请选择"></el-date-picker>
        </el-form-item>
        <el-form-item label="退费状态：" prop="_3" required>
          <el-select v-model="form._3" placeholder="请选择">
            <el-option value="0" label="未退费"></el-option>
            <el-option value="1" label="已退费"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退费方式：" prop="_4" required>
          <el-select v-model="form._4" placeholder="请选择">
            <el-option value="0" label="未退费"></el-option>
            <el-option value="1" label="已退费"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="家长银行账户：" prop="_5" required>
          <el-input type="number" v-model="form._5" placeholder="请输入卡号"/>
        </el-form-item>
        <el-form-item label="开户行：" prop="_6" required>
          <el-input v-model="form._6" Placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="开户人：" prop="_7" required>
          <el-input v-model="form._7"  Placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="退费办理人：" prop="_8" required>
          <el-input v-model="form._8"  Placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="说明：" prop="_9" required>
          <el-input type="textarea" v-model="form._9" class="el-input" resize="none" Placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="退费凭证：" prop="_10">
          <t-upload v-model="form._10"></t-upload>
        </el-form-item>
      </el-form>
    </t-card>-->
    <refund-edit v-model="form" ref="form"/>
    <div class="footer-button">
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card";
import Upload from "@/components/common/upload";
import axios from "@/utils/axios.register";
import RefundEdit from "../components/RefundEdit";
export function getBaseInfo(id){
  return axios.get('api/refund/fusion/refund-show?id=' + id)
}
export function getRefundInfo(id){
  return axios.get('api/refund/fusion/refund-details?refund_offline_id=' + id)
}
let loading = null;
export default {
	_config:{"route":{"path":"refund","meta":{"title":"发起退费","style":0}}},
  data(){
    let refund_offline_id = this.$route.query.refund_offline_id
    return {
      titleStyle:{
        borderBottom:'1rem solid #eee',
        paddingBottom:'10rem',
        display:'block'
      },
      refund_offline_id,
      loading:false,
      baseInfo:{},
      // 表单数据
      form:{
      },
    }
  },
  watch:{

    loading(val){
      if(val)loading=this.$loading({text:"请稍后..."})
      else loading.close();
    }
  },
  mounted(){
    this.loading = true;
    if(this.refund_offline_id == null) {
      getBaseInfo(this.$route.query.id).then(res => {
        this.baseInfo = res.data.data;
        this.form.order_id = this.baseInfo.order_id;
      }).finally(_=>this.loading=false)
    }else{
      getRefundInfo(this.refund_offline_id).then(res =>{
        this.baseInfo = res.data.data;
        this.form = res.data.data;
      }).finally(_=>this.loading = false)
    }
  },
  methods:{
    submit(){
      this.$refs.form.validate((val)=>{
        if (!val)return;
        let msg = this.refund_offline_id == null?"是否确认发起退费?":"是否确认更改退费详情?"
        let btnText=this.refund_offline_id == null?"发起退费":"确认";
        this.$confirm(msg,{
          cancelButtonText:"点错了",
          confirmButtonText:btnText,
        }).then(res=>{
          if(this.refund_offline_id != null){
            this.onRefundChange()
          }else this.onRefund()
        }).catch(res=>{
          console.log(res,",,,,,")
        })
      })

    },
    // 发起退费
    onRefund(){
      let loading = this.$loading({text:"请稍后..."})
      this.$_register.post("api/refund/fusion/refund-add",this.form).then(res=>{
        this.$router.replace("./refund-details?refund_offline_id="+res.data.data.refund_offline_id)
      }).finally(_=>loading.close())
    },
    // 变更
    onRefundChange(){
      let loading = this.$loading({text:"请稍后..."})
      this.$_register.post("api/refund/fusion/refund-edit",this.form).then(res=> {
        this.$router.back();
      }).finally(_=>loading.close())
    }
  },
  components:{
    TCard:Card,
    TUpload:Upload,
    RefundEdit
  }
}
</script>
<style lang="scss" scoped>
.refund{
  background: transparent !important;
  .card{
    background: white;
    padding:10rem;
    margin-bottom: 20rem;
    .content{
      padding: 20rem 30rem;
    }
  }
  .footer-button{
    background: white;
    border: none;
    line-height: 80rem;
  }
}
</style>
